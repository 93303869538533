<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">工程咨询中心</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">大连工程咨询中心有限公司成立于2018年11月20日，是大连市城市建设投资集团有限公司的全资二级子公司。注册资本1000万元人民币，注册地址为中山区解放路347号葵英大厦904室。为进一步整合资源，按照集团公司的业务发展布局，2023年5月由集团公司二级子公司--大连市国土空间规划设计有限公司托管，以实现人员、技术、业务等互补，打造设计咨询业务板块。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">经中国工程咨询协会认定，大连工程咨询中心有限公司现有甲级专业资信2个，分别为建筑、市政公用工程；经大连市工程咨询协会认定，现有乙级专业资信2个，分别为机械（含智能制造）、电子信息工程。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">经营范围是：工程项目咨询服务， 招标代理，政府采购代理咨询，工程造价咨询，工程管理服务，工程监理服务，工程勘察活动，土地规划服务，市场调查，经济信息咨询，健康咨询，环保咨询，信息技术咨询服务，节能技术推广服务、信用服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动）。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">目前，大连工程咨询中心有限公司以招标代理、造价咨询、项目可行性研究报告（项目建议书、资金申请报告等）编制评审为主要业务，逐步向全过程咨询转型发展。</span>
        </p>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>